.logo {
  width: 183px;
  height: 50px;
  display: inline-block;
  margin-top: 4px;
  z-index: 500;
}
.logo2 {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-top: 4px;
  z-index: 15;
}
.logo3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 4px;
  z-index: 15;
}
.circ{
  border-radius: 50%;
}

.circ:before{
  position: absolute; 
  left: 0; 
  width: 0; 
  height: 0;
}

.circ:after{
  position: absolute; 
  left: 0; 
  width: 0; 
  height: 0;
}
body.light-mode .textColor {
  color: rgb(70, 70, 70);
}
body.dark-mode .textColor {
  color: rgb(107, 107, 107);
}

.timeline {
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;
  z-index: 1;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 2px;
  height: 100%;
  margin-left: -1px;
}
body.light-mode .timelineColor:before {
  background: rgb(213,213,213);
  background: -moz-linear-gradient(top, rgba(213,213,213,0) 0%, rgb(213,213,213) 8%, rgb(213,213,213) 92%, rgba(213,213,213,0) 100%);
  background: -webkit-linear-gradient(top, rgba(213,213,213,0) 0%, rgb(213,213,213) 8%, rgb(213,213,213) 92%, rgba(213,213,213,0) 100%);
  background: -o-linear-gradient(top, rgba(213,213,213,0) 0%, rgb(213,213,213) 8%, rgb(213,213,213) 92%, rgba(213,213,213,0) 100%);
  background: -ms-linear-gradient(top, rgba(213,213,213,0) 0%, rgb(213,213,213) 8%, rgb(213,213,213) 92%, rgba(213,213,213,0) 100%);
  background: linear-gradient(to bottom, rgba(213,213,213,0) 0%, rgb(213,213,213) 8%, rgb(213,213,213) 92%, rgba(213,213,213,0) 100%);
}
body.dark-mode .timelineColor:before {
  background: #1e2020;
  background: -moz-linear-gradient(top, rgba(30,32,32,0) 0%, rgb(30,32,32) 8%, rgb(30,32,32) 92%, rgba(30,32,32,0) 100%);
  background: -webkit-linear-gradient(top, rgba(30,32,32,0) 0%, rgb(30,32,32) 8%, rgb(30,32,32) 92%, rgba(30,32,32,0) 100%);
  background: -o-linear-gradient(top, rgba(30,32,32,0) 0%, rgb(30,32,32) 8%, rgb(30,32,32) 92%, rgba(30,32,32,0) 100%);
  background: -ms-linear-gradient(top, rgba(30,32,32,0) 0%, rgb(30,32,32) 8%, rgb(30,32,32) 92%, rgba(30,32,32,0) 100%);
  background: linear-gradient(to bottom, rgba(30,32,32,0) 0%, rgb(30,32,32) 8%, rgb(30,32,32) 92%, rgba(30,32,32,0) 100%);
}

.timeline li {
  padding: 1em 0;
  z-index:2;
}

.timeline .circ{
  width: 16px;
  height: 16px;
  position: absolute;
  background: #67b771;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left:auto;
  margin-right:auto;
  top: -30px;
  margin-top: 0;
}

.timeline .circ:before {
  border-bottom: 4px solid #67b771;
  border-left-width: 8px;
  border-right-width: 8px;
  top: -4px;
}

.timeline .circ:after {
  border-left-width: 8px;
  border-right-width: 8px;
  border-top: 4px solid #67b771;
  bottom: -4px;
}

.direction-l,
.direction-r {
  float: none;
  width: 100%;
  text-align: center;
}

.flag-wrapper {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
}

body.light-mode .flag {
  position: relative;
  display: inline;
  background: #f2f5f8;
  font-weight: 600;
  z-index: 15;
  padding: 6px 10px;
  text-align: left;
  border-radius: 5px;
}
body.dark-mode .flag {
  position: relative;
  display: inline;
  background: #1e2020;
  font-weight: 600;
  z-index: 15;
  padding: 6px 10px;
  text-align: left;
  border-radius: 5px;
}

.direction-l .flag:after,
.direction-r .flag:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  height: 0;
  width: 0;
  margin-left: -8px;
  border: solid transparent;
  border-width: 8px;
  pointer-events: none;
}

.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
}

body.light-mode .desc {
  position: relative;
  border-radius: 5px;
  background: #f2f5f8;
  padding: 1em;
  -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.10);
  -moz-box-shadow: 0 0 1px rgba(0,0,0,0.10);
  box-shadow: 0 0 1px rgba(0,0,0,0.10);
  z-index: 15;
}
body.dark-mode .desc {
  position: relative;
  border-radius: 5px;
  background: #1e2020;
  padding: 1em;
  -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.10);
  -moz-box-shadow: 0 0 1px rgba(0,0,0,0.10);
  box-shadow: 0 0 1px rgba(0,0,0,0.10);
  z-index: 15;
}

.direction-l .desc,
.direction-r .desc {
  position: relative;
  margin: 0em 0em 0 0em;
  z-index: 15;
}

@media(min-width: 992px){
  .timeline {
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  
  .timeline .circ {
    left: -108px;
    right: auto;
    top: 8px;
  }

  .timeline .direction-l .circ {
    left: auto;
    right: -28px;
  }
.timeline .direction-r .circ {
  left: -28px;
  right: auto;
}
  .direction-l {
    position: relative;
    width: 1000px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 1000px;
    float: right;
    text-align: left;
  }

  .flag-wrapper {
    display: inline-block;
  }
  
  .flag {
    font-size: 18px;
  }

  .time-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }

  .direction-l .time-wrapper {
    left: auto;
    right: -132px;
  }

  .direction-r .time-wrapper {
    left: -132px;
    right: auto;
    top: 8px;
  }

  .time {
    padding: 5px 10px;
  }

  .direction-r .desc {
    margin: 0em 0 0 0em;
  }
}

@media(min-width: 992px){
  .timeline {
    width: 800px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .direction-l {
    position: relative;
    width: 380px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 380px;
    float: right;
    text-align: left;
  }
}