@keyframes cf3FadeInOut {
  0% {
  opacity:1;
  }
  45% {
  opacity:1;
  }
  55% {
  opacity:0;
  }
  100% {
  opacity:0;
  }
}
body.light-mode .box {
  position:absolute;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url('../Assets/websitePic.0.navColor.jpg');
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size: cover;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  animation-name: cf3FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  animation-direction: alternate;
}
.box0 {
  position:absolute;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url('../Assets/websitePic.1.jpg');
  background-position:60% 50%;
  background-repeat:no-repeat;
  background-size: cover;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
body.dark-mode .box {
  position:absolute;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url('../Assets/websitePic.0.dark.jpg');
  border: 0;
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size: cover;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  animation-name: cf3FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  animation-direction: alternate;
}
.homeImgWrapper {
  position:relative;
  height: 100vh;
}

body.light-mode .extraSpace {
  width: 100%;
  height: 200px;
  background: #e8ecef;
}
body.dark-mode .extraSpace {
  width: 100%;
  height: 200px;
  background: #121313;
}

/* Animation translate up text */

@keyframes fade-up {
  0% {
    opacity:1;
    transform: translateY(0);
  }
  45% {
    opacity:1;
  }
  55% {
    opacity:0;
  }
  100% {
    opacity:0;
    transform: translateY(3em);
  }
}
@keyframes fade-up1 {
  0% {
    opacity:0;
    transform: translateY(0)
  }
  45% {
    opacity:0;
  }
  55% {
    opacity:1;
  }
  100% {
    opacity:1;
    transform: translateY(3em);
  }
}

body.light-mode .fade-up {
  position:absolute;
  animation: fade-up 4s cubic-bezier(.02,.98,.17,.97) forwards;
  margin-left: 4%;
  margin-bottom: 0%;
  width: 42%;
  color: #333;
  font-family: 'Anton', sans-serif;
  font-size: 72px;
  text-align: left;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  animation-direction: alternate;
}
body.dark-mode .fade-up {
  position:absolute;
  animation: fade-up 4s cubic-bezier(.02,.98,.17,.97) forwards;
  top: 0;
  margin-left: 4%;
  margin-bottom: 0%;
  width: 42%;
  font-family: 'Anton', sans-serif;
  font-size: 72px;
  text-align: left;
  color: rgb(107, 107, 107);
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  animation-direction: alternate;
}
.fade-up1 {
  position:absolute;
  animation: fade-up1 4s cubic-bezier(.02,.98,.17,.97) forwards;
  margin-left: 4%;
  margin-bottom: 0%;
  width: 70%;
  font-family: 'Anton', sans-serif;
  font-size: 72px;
  color: #67b771;
  text-align: left;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 8s;
  animation-direction: alternate;
}

.header.fade-up {
  opacity: 0;
}

.header.fade-up1 {
  opacity: 0;
}

@media (min-width: 32em) {

.fade-up1 {
  font-size: 82px;
}
body.light-mode .fade-up {
  font-size: 82px;
}
body.dark-mode .fade-up {
  font-size: 82px;
}
  
}