.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.box3 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding-top: 50px;
}
body.dark-mode .color {
  background: #121313;
}
body.light-mode .color {
  background: #e8ecef;
}
.hide {
    visibility: hidden;
}


.box2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

body.light-mode .sectionTitle {
  font-size: 24px;
  font-weight: 400;
  color: gray;
  align-self: flex-start;
  flex-basis: 30%;
  min-width: 150px;
  margin: auto;
}
body.dark-mode .sectionTitle {
  font-size: 24px;
  font-weight: 400;
  color: rgb(107, 107, 107);
  align-self: flex-start;
  flex-basis: 30%;
  min-width: 150px;
  margin: auto;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.whiteSpace {
    flex-basis: 60%;
    margin: auto;
}
.moreSpace {
    width: 100%;
    height: 50px;
}

body.light-mode .squareEmail {
    margin-top: 20px;
    height: 100px;
    width: 100px;
    background-color: #d1d5d9;
    background-image: url('../Assets/email.png');
    background-position:50% 50%;
    background-repeat:no-repeat;
    background-size: 70%;
}
body.dark-mode .squareEmail {
  margin-top: 20px;
  height: 100px;
  width: 100px;
  background-color: #2b2c2c;
  background-image: url('../Assets/email.png');
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size: 70%;
}
body.light-mode .squarePhone {
    margin-top: 20px;
    height: 100px;
    width: 100px;
    background-color: #d1d5d9;
    background-image: url('../Assets/phone.png');
    background-position:50% 50%;
    background-repeat:no-repeat;
    background-size: 50%;
}
body.dark-mode .squarePhone {
  margin-top: 20px;
  height: 100px;
  width: 100px;
  background-color: #2b2c2c;
  background-image: url('../Assets/phone.png');
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size: 50%;
}

body.light-mode .rectangle {
  margin-top: 20px;
  display: flex;
  flex-basis: 30%;
  min-width: 220px;
  padding-left: 20px;
  padding-right: 20px;
  height:100px;
  background-color: #f2f5f8;
  font-family: 'Anton', sans-serif;
  font-weight: 200;
  align-items: center;
  justify-content: center;
  color: gray;
  align-self: center;
}
body.dark-mode .rectangle {
  margin-top: 20px;
  display: flex;
  flex-basis: 30%;
  min-width: 220px;
  padding-left: 20px;
  padding-right: 20px;
  height:100px;
  background-color: #1e2020;
  font-family: 'Anton', sans-serif;
  font-weight: 200;
  align-items: center;
  justify-content: center;
  color: gray;
  align-self: center;
}
.email {
    font-family: 'Anton', sans-serif;
    font-weight: 200;
    align-items: center;
    justify-content: center;
    color: #67b771;
    cursor: pointer;
}


/* Resume stuff */
body.light-mode .downloadBtn {
    background-color: #67b771;
    border: none;
    color: #f2f5f8;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
}
body.dark-mode .downloadBtn:hover {
  background-color: black;
  color: #67b771;
}
body.light-mode .downloadBtn:hover {
  background-color: #f2f5f8;
  color: #67b771;
}
body.dark-mode .downloadBtn {
  background-color: #67b771;
  border: none;
  color: black;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
}

