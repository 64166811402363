* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type:none;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

/* CIRCLE WITH PIC */
.circleBase {
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 8px;
}

.type1 {
  width: 90%;
  padding-top: 90%;
  margin-top: -20px;
  position: relative;
  background-image: url('../Assets/profPic.jpg');
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size: cover;
}

.name {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 8px;
  font-weight: 700;
  text-align: center;
}
body.light-mode .nameColor {
  color: rgb(70, 70, 70);
}
body.dark-mode .nameColor {
  color: rgb(107, 107, 107);
}
.job {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 8px;
  font-weight: 200;
  text-align: center;
  color: black;
}
.textHide {
  font-size: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 0;
  color: #d1d5d9;
  text-align: center;
}

/* BUTTON */
.bttn {
  text-align: center;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
  display: block;
  font-size: 18px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-weight: 400;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
body.light-mode .bttnColor {
  color: rgb(70, 70, 70);
}
body.dark-mode .bttnColor {
  color: rgb(107, 107, 107);
}
body.light-mode .bttnColor:hover {
  color: black;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
body.dark-mode .bttnColor:hover {
  color: black;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
.bttn:after {
  content: '';
  width: 0; /*set width to 0 initially, so animates from nothing */
  height: 2px; /*height of underline*/
  background: #67b771;
  display: block; 
  /* animation settings
  0.2s duration, 0.1s delay, with easing */
  transition: width 0.2s 0.1s ease-in-out; 
}
.bttn:hover:after {
  /* whatever width you want the underline to animate to */
  width: 100%; 
}

.after-from-center:after {   
  /* center the underline
  so animation will occur from the center.
  remove this class if you want animation from the left instead */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.constrain-after:after {
  position: absolute;
}

#prod-menu li.sel a button {
  color:#67b771;
}
#prod-menu li.sel a i {
  color:#67b771;
}
#prod-menu li.sel a .bttn:after {
  content: '';
  width: 100%; /*set width to 0 initially, so animates from nothing */
  height: 2px;
  background: #67b771;
  display: block; 
  transition: width 0.2s 0.1s ease-in-out; 
}

#prod-menu li.sel a .after-from-center:after {   
  /* center the underline
  so animation will occur from the center.
  remove this class if you want animation from the left instead */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#prod-menu li.sel a .constrain-after:after {
  position: absolute;
}

/* Layout */
.s-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
/* body.dark-mode .color {
  background: #121313;
}
body.light-mode .color {
  background: #e8ecef;
} */

.s-layout__content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4em;
  overflow-x: hidden;
  float: right;
}

/* Sidebar */
.s-sidebar__trigger {
  position: fixed;
  top: 0;
  left: 0;
  background: #d1d5d9;
  z-index: 500;
  display: none
}

.s-sidebar__trigger > i {
  display: inline-block;
  margin: 1.5em 0 0 1.5em;
  color: #67b771;
}

.s-sidebar__nav {
  position: fixed;
  top: 0;
  overflow: hidden;
  transition: all .3s ease-in;
  width: 4em;
  left: 0;
  height: 100%;
  background: #d1d5d9;
  color: rgba(255, 255, 255, 0.7);
  z-index: 500;
}
body.light-mode .colors {
  background: #d1d5d9;
}
body.dark-mode .colors {
  background: #2b2c2c;
}

.s-sidebar__nav .wrapper{
  visibility: show;
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}

.s-sidebar__nav ul {
  position: absolute;
  left: 0;
  margin: 0;
  padding: 0;
  width: 15em;
}

.s-sidebar__nav ul li {
  width: 100%;
}

.s-sidebar__nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3em;
}
.s-sidebar__nav-togg {
  position: relative;
  vertical-align: bottom;
  width: 100%;
  height: 3em;
}

.s-sidebar__nav-link em {
  position: absolute;
  top: 50%;
  left: 4em;
  transform: translateY(-50%);
}

body.light-mode .s-sidebar__nav-link > i {
  position: absolute;
  top: -0.5em;
  left: 0;
  display: inline-block;
  width: 4em;
  height: 4em;
  color: #2b2c2c;
}
body.dark-mode .s-sidebar__nav-link > i {
  position: absolute;
  top: -0.5em;
  left: 0;
  display: inline-block;
  width: 4em;
  height: 4em;
  color: rgb(107, 107, 107);
}

.s-sidebar__nav-link > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* TOGGLE */

.toggle-control {
  position: relative;
  padding: 16px 4px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
input[type='checkbox'].dmcheck {
  width: 40px;
  height: 10px;
  background: #555;
  position: relative;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;
}

.dmcheck:checked + label {
  left: 30px;
}

.dmcheck:focus-visible {
  outline: solid 2px white;
}

.dmcheck + label {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
  position: absolute;
  left: 2px;
  background: #fff;
  opacity: 0.9;
  background-color: #f6f6f6;
}
.dark-mode-toggle {
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.dark-mode-toggle {
  display: flex;
  margin: 0 auto;

}
body.light-mode .lightButton {
  font-size: 1.6em;
  margin-left: 19px;
  background: none;
  border: none;
  color: #ccb960;
  cursor: pointer;
  transition: color 0.3s ease;
}
body.dark-mode .lightButton {
  font-size: 1.2em;
  margin-left: 22px;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  transition: color 0.3s ease;
}
body.light-mode .darkButton {
  font-size: 1.2em;
  margin-left: 25px;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  transition: color 0.3s ease;
}
body.dark-mode .darkButton {
  font-size: 1.6em;
  margin-left: 20px;
  background: none;
  border: none;
  color: #164885;
  cursor: pointer;
  transition: color 0.3s ease;
  padding-left: 2px;
}
.dark-mode-toggle:last-child {
  color: #666;
}

.dark-mode-toggle:focus {
  outline: none;
}

/* Mobile First */

@media (min-width: 42em) {

  .s-layout__content {
    margin-left: 4em;
  }
  .s-sidebar__trigger {
    display: none
  }
  
  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }
  
}


@media (min-width: 68em) {
  .s-sidebar__nav .wrapper{
    visibility: show;
  }
  .s-sidebar__trigger {
    display: none
 }
  .s-layout__content {
    margin-left: 15em;
  }
  
  .s-sidebar__nav {
    width: 15em;
  }

  body.light-mode .s-sidebar__nav-link > i {
    color: #d1d5d9;
  }
  body.dark-mode .s-sidebar__nav-link > i {
    color: #2b2c2c;
  }
  .toggle-control {
    position: relative;
    padding: 16px 4px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .dark-mode-toggle {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  body.light-mode .lightButton {
    margin-left: 0px;
  }
  body.dark-mode .lightButton {
    margin-left: 0px;
  }
  body.light-mode .darkButton {
    margin-left: 0px;
  }
  body.dark-mode .darkButton {
    margin-left: 0px;
  }
}



