.box3 {
    display: flex;
    width: 100%;
    /* height: 100vh; */
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding-top: 50px;
}
body.dark-mode .color {
  background: #121313;
}
body.light-mode .color {
  background: #e8ecef;
}
.sectionHeader {
    font-size: 18px;
    font-weight: 400;
    color: gray;
    align-self: flex-start;
    flex-basis: 30%;
    margin: auto;
}
.filter {
  padding: 12px;
  font-weight: 400;
  color: gray;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.whiteSpace {
    flex-basis: 60%;
    margin: auto;
}
.moreSpace {
    width: 100%;
    height: 50px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
}

.card_image {
  height: auto;
  width: 100%;
  vertical-align: middle;
  filter: contrast(70%);
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
}

.linkStlye {
  color: gray;
}
.linkStlye:hover {
  color: #67b771;
}

.btn {
  color: gray;
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  border: none;
  background: transparent;
}

.btn:hover {
  color: #67b771;
}

.cards {
  display: flex;
  width:100%;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.card:hover .card_image{
    filter: contrast(100%);
}

.cards_item {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}

@media (min-width: 500px) {
    .cards_item {
      width: 100%;
    }
}
@media (min-width: 640px) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 896px) {
  .cards_item {
    width: 33.3333%;
  }
}

body.light-mode .card {
    background-color: #f2f5f8;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
    display: flex;
    width:100%;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
}
body.dark-mode .card {
  background-color: #1e2020;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
  display: flex;
  width:100%;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0 1rem;
}

.card_title {
  color: #696969;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.card_text {
  color: #696969;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}




/* Filter buttons style */
body.light-mode .filterBtn {
  background-color: #d1d5d9;
  text-decoration: none;
  color: gray;
  font-weight: 600;
  margin: 5px;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
}
body.dark-mode .filterBtn {
  background-color: #2b2c2c;
  text-decoration: none;
  color: gray;
  font-weight: 600;
  margin: 5px;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
}

body.light-mode .filterBtn:hover {
  background-color: #f2f5f8;
  color: #67b771;
  box-shadow: 0;
}
body.dark-mode .filterBtn:hover {
  background-color: black;
  color: #67b771;
  box-shadow: 0;
}

body.light-mode .filterBtnActive {
  background-color: #67b771;
  color: #f2f5f8;
}
body.dark-mode .filterBtnActive {
  background-color: #67b771;
  color: black;
}

body.light-mode .tag {
  background-color: #67b771;
  color: #f2f5f8;
  padding: 3px;
  font-weight: 200;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 2px;
}
body.dark-mode .tag {
  background-color: #67b771;
  color: black;
  padding: 3px;
  font-weight: 200;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 2px;
}

.filtersWrapper {
  display: inline-block;
}