.box2 {
    display: flex;
    flex-basis: 100%;
    flex: 1;
    width: 100%;
    height: 100vh;
    background-color: #e8ecef;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
body.dark-mode .color {
  background: #121313;
}
body.light-mode .color {
  background: #e8ecef;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap-reverse;
}

.extraSpace {
  width: 100%;
  height: 100px;
}

body.light-mode .text {
    flex-basis: 30%;
    margin-left: auto;
    margin-right: auto;
    min-width: 250px;
    padding-top: 50px;
    color: rgb(70, 70, 70);
}
body.dark-mode .text {
  flex-basis: 30%;
  margin-left: auto;
  margin-right: auto;
  min-width: 250px;
  padding-top: 50px;
  color: rgb(107, 107, 107);
}


.whiteSpace {
    flex-basis: 60%;
    margin: auto;
}

body.light-mode .aboutMe {
  font-size: 24px;
  font-weight: 400;
  color: gray;
  align-self: flex-start;
  flex-basis: 30%;
  min-width: 150px;
  margin: auto;
}
body.dark-mode .aboutMe {
  font-size: 24px;
  font-weight: 400;
  color: rgb(107, 107, 107);
  align-self: flex-start;
  flex-basis: 30%;
  min-width: 150px;
  margin: auto;
}

.wrapper {
    width: 80%;
    height: auto;
    margin: 0 auto;
    flex-basis: 60%;
    padding-top: 50px;
}

.vid {
  
  width: 250px;
    height: 150px;
}

@media (min-width:  600px) {
  .vid {
    width: 600px;
  height: 420px;
  }
}
  
